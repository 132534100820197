import { AltLayout } from '../layouts/AltLayout';
import Button from '@/common/button';
import { SEO } from '@/common/seo';

const Radio: Page = () => (
    <AltLayout
        heroButtonText="Get In Touch With Us"
        heroImg="RadioHero.jpg"
        label="Radio"
        title="Dr Zapata Interview"
        description="Our very own Dr Rick Zapata was invited on a radio show to talk about his expertise"
    >
        <div className="my-16 lg:mt-[120px] lg:mb-[100px]">
            <p className="DH2MH3 text-green  text-center mb-4 leading-none">Radio Interview</p>
            <p className="DP1MP1 text-blackT text-center mb-8 ">
                Dr. Zapata has been interviewed live in the studios of 101.9FM Campesina
            </p>
            <Button
                text="Watch The Video"
                className="bg-green border-none text-white mx-auto mb-12"
            />

            <div className="flex justify-center items-center bg-redbg rounded-lg h-[300px] sm:h-[400px] lg:h-[650px]  mx-auto">
                <iframe
                    title="interview"
                    width="100%"
                    height="100%"
                    src="https://www.youtube-nocookie.com/embed/CrQeTv0qN8o"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="-mt-4 -ml-4 mb-2 rounded-lg"
                ></iframe>
                {/* <video className="-mt-4 -ml-4 mb-2 rounded-lg" controls>
                    <track kind="captions" />
                    <source
                        src="https://www.youtube.com/watch?v=l8DCPaHc5TQ&ab_channel=Chandoo"
                        type="video/youtube"
                    />
                </video> */}
            </div>
        </div>
    </AltLayout>
);

export default Radio;

export const Head = () => (
    <SEO
        title="Best Dentist in Phoenix - Dr. Rick Zapata - zapatadental.com"
        description="Our own Dr. Rick Zapata was invited on a  radio show and interviewed live in the studio of 101.9 FM Campesina, to talk about his expertise in dentistry."
        pathname="/radio-stations/"
    />
);
