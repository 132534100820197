import ContactForm from '@/common/contact-form';
import Custom_hero from '@/common/custom_hero';
interface Props {
    children?: React.ReactNode;
    heroImg?: string;
    title?: string;
    description?: React.ReactNode;
    label?: string;
    logo?: string;
    heroButtonLink?: string;
    heroButtonText?: string;
}
export const AltLayout = ({
    children,
    heroImg,
    title,
    description,
    label,
    logo,
    heroButtonText,
    heroButtonLink,
}: Props) => {
    return (
        <div className="px-0 lg:px-[70px] 3xl:px-[150px] lg:mt-16 mt-0 mb-28 max-w-[1920px] mx-auto">
            <Custom_hero
                title={title}
                logo={logo}
                description={description}
                label={label}
                heroImage={heroImg}
                buttonText={heroButtonText}
                buttonLink={heroButtonLink}
            />
            <div className="px-[16px] lg:px-0 ">
                {children}
                <div>
                    <ContactForm />
                </div>
            </div>
        </div>
    );
};
