import React from 'react';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

interface Props {
    title?: string;
    description?: string;
    pathname?: string;
    children?: React.ReactNode;
}

export const SEO = ({ title, description, pathname, children }: Props) => {
    const { title: defaultTitle, description: defaultDescription, siteUrl } = useSiteMetadata();

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
    };

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            <meta name="title" content={seo.title} />
            <meta name="url" content={seo.url} />
            {children}
        </>
    );
};
