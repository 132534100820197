import Button from '@/common/button';
import Image from '@/common/image';

interface props {
    title?: string;
    logo?: string;
    description?: React.ReactNode;
    heroImage?: string;
    buttonText?: string;
    label?: string;
    buttonLink?: string;
}
const Custom_hero = ({
    label,
    title,
    description,
    heroImage,
    buttonText,
    buttonLink,
    logo,
}: props) => {
    return (
        <div className="flex flex-col-reverse lg:flex-row">
            <div className=" bg-garybg grid items-center -mt-10 lg:mt-0 z-10 lg:z-0 mx-4 lg:mx-0 rounded-2xl">
                <div className=" lg:pl-16 pl-4 py-8">
                    <p className="CustomHeroLabel text-green mb-4">{label}</p>
                    {title && <p className="DH2MH3 mb-4">{title}</p>}
                    {logo && (
                        <div className="w-[110px] lg:w-[243px]">
                            <Image filename={logo} alt="customlogo" />
                        </div>
                    )}
                    <p className="DH4MH2 text-blackT mt-6 mb-6 lg:mb-12 pr-4 lg:pr-40">
                        {description}
                    </p>
                    {buttonText && (
                        <a
                            href={buttonLink || '#contact'}
                            target={buttonLink?.startsWith('https') ? '_blank' : '_self'}
                            rel="noreferrer"
                        >
                            <Button
                                className="bg-green border-none text-white "
                                text={buttonText}
                                arrow={true}
                            />
                        </a>
                    )}
                </div>
            </div>
            <div className="lg:w-[85%] lg:max-w-[850px] lg:my-auto w-full ">
                <Image
                    filename={heroImage}
                    alt="Hero_Image"
                    className="-ml-[108px] lg:my-8 my-0 lg:rounded-2xl"
                />
            </div>
        </div>
    );
};

export default Custom_hero;
